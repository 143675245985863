import React from 'react'
import { CartProvider } from 'react-use-cart'

const wrap = ({ element }) => (
  <>
    <CartProvider>{element}</CartProvider>
  </>
)

export const wrapPageElement = wrap
